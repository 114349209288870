export const ListIcon = [
  {
    url: "https://www.instagram.com/ristek.csui/",
  },
  {
    url: "https://twitter.com/RistekCSUI",
  },
  {
    url: "https://www.linkedin.com/company/ristek-fakultas-ilmu-komputer-universitas-indonesia",
  },
  {
    url: "https://liff.line.me/1645278921-kWRPP32q?accountId=rye2953f&openerPlatform=webview&openerKey=webview%3AunifiedSearch",
  },
  {
    url: "https://medium.com/ristek-fasilkom",
  },
];
