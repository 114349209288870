import React from "react";

export const ListMatkulIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="currentColor"
      viewBox="0 0 24 25"
    >
      <path d="M21.375 5H7.687a.188.188 0 00-.187.188V6.5c0 .103.084.188.188.188h13.687a.188.188 0 00.188-.188V5.187A.188.188 0 0021.375 5zm0 6.656H7.687a.188.188 0 00-.187.188v1.312c0 .103.084.188.188.188h13.687a.188.188 0 00.188-.188v-1.312a.188.188 0 00-.188-.188zm0 6.656H7.687a.188.188 0 00-.187.188v1.313c0 .103.084.187.188.187h13.687a.188.188 0 00.188-.188V18.5a.188.188 0 00-.188-.188zM2.437 5.845a1.312 1.312 0 102.625 0 1.312 1.312 0 00-2.625 0zm0 6.656a1.313 1.313 0 102.626 0 1.313 1.313 0 00-2.625 0zm0 6.656a1.312 1.312 0 102.625 0 1.312 1.312 0 00-2.625 0z"></path>
    </svg>
  );
};
